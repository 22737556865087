import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "store/rootReducer";

import { sendPasswordReset } from "api/auth";

import { BaseButton, Heading } from "components/base";
import FieldErrorMessage from "components/base/FieldErrorMessage";
import PasswordInput from "components/base/PasswordInput";
import { IconSpinner } from "components/icons";

import "./Login.scss";
import "./ResetPassword.scss";

function validatePassword(pw) {
  return /[A-Z]/.test(pw) && /[a-z]/.test(pw) && /[0-9]/.test(pw) && pw.length >= 12;
}
export default function ResetPassword() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  const data = {
    passwordLabel: "Your password"
  };
  const [password, setPassword] = useState("");
  const [headerTitle] = useState("Password Recovery");
  const [errors, setErrors] = useState("");
  const [isBusy, setBusy] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const user = useSelector((state: RootState) => state.auth.user);
  const history = useHistory();
  useEffect(() => {
    if (user) {
      history.push("/");
    }
  }, [user, history]);

  function changePassword(event) {
    event.preventDefault();
    if (!validatePassword(password)) {
      setErrors(
        "Your password length must be at least 12 characters long and contain an uppercase letter and number."
      );
      return;
    }
    setBusy(true);
    sendPasswordReset(
      token,
      password,
      () => {
        setBusy(false);
        setPasswordChanged(true);
      },
      () => {
        setErrors(
          "We're sorry, an error occurred so we're unable to change your password at this time."
        );
        setBusy(false);
      }
    );
  }
  return (
    <div className="content-container">
      <div>
        <Heading element="h3" className="align-center medium">
          {headerTitle}
        </Heading>
        {!passwordChanged && (
          <>
            <form name="login" className="reset-form" onSubmit={changePassword}>
              <div>Create a new password</div>
              <fieldset className="fieldset">
                <PasswordInput
                  value={password}
                  label={data.passwordLabel}
                  onChange={setPassword}
                  required
                />

                <div className="flex items-center justify-center">
                  <BaseButton type="submit" appearance="primary" className="justify-end">
                    Save Password
                  </BaseButton>
                </div>
              </fieldset>
              {errors.length > 0 && <FieldErrorMessage message={errors} />}
            </form>
          </>
        )}
        {passwordChanged && (
          <fieldset className="fieldset">
            <div>Your password has been successfully changed.</div>{" "}
            <div className="flex items-center justify-center full-width">
              <BaseButton
                appearance="primary"
                onClick={() => {
                  history.push("/login");
                }}>
                Back to Login
              </BaseButton>
            </div>
          </fieldset>
        )}
        <div className={`busy-indicator ${!isBusy ? "hidden" : ""}`}>
          <IconSpinner></IconSpinner>
        </div>
      </div>
    </div>
  );
}
