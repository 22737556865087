import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { updateUserDefinedColumns } from "store/features";

import { deleteUserDefinedColumn } from "api/data";

import { EntityKind } from "models/entityKind";

import { useGroupByState, useGroupByUpdater } from "../group-by.context";

interface UseFieldDeleteMutationParams {
  ids: string[];
  isOrg: boolean;
  entityKind: EntityKind;
}

const useFieldDeleteMutation = ({
  ids,
  isOrg,
  entityKind = EntityKind.Well
}: UseFieldDeleteMutationParams) => {
  const dispatch = useDispatch();
  const { checkedFields } = useGroupByState();
  const stateDispatch = useGroupByUpdater();
  const { isLoading, mutate } = useMutation(
    () => {
      return deleteUserDefinedColumn(ids, isOrg, entityKind);
    },
    {
      onSuccess: () => {
        stateDispatch({
          type: "checked_fields",
          payload: checkedFields.filter((checkedItem) => !ids.includes(checkedItem))
        });

        dispatch(updateUserDefinedColumns(entityKind));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        if (err.response?.data) {
          toast.error(`${err?.response.data}`);
        } else {
          toast.error(
            "We're sorry a network error occurred. The field could not be deleted."
          );
        }
      }
    }
  );

  return { isLoading, mutate };
};

export default useFieldDeleteMutation;
