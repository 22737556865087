import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { FORECASTING_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { UpdateForecastWell } from "../models/forecastWell";
import { FetchForecastWellQuery } from "./useFetchForecastWell";

const API_URL = `${FORECASTING_SERVICE_ADDRESS}/api/v1`;

const useUpdateForecastWell = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params: { uwi: string; update: UpdateForecastWell }) => {
      const forecastUpdate = params.update.forecast;
      forecastUpdate.segments.forEach((segment) => {
        //ensure the dates have timezone in the string
        segment.startDate = new Date(segment.startDate).toISOString();
        segment.endDate = new Date(segment.endDate).toISOString();
      });

      const response = await axios.put(
        `${API_URL}/well/${params.uwi}/forecast`,
        params.update
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FetchForecastWellQuery);
      }
    }
  );
};

export default useUpdateForecastWell;
