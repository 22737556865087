export const typeWellProductsMenuItems = (kind: "Forecasting" | "TypeWell") =>
  [
    {
      label: "Oil",
      key: "OIL"
    },
    {
      label: "Condensate",
      key: "CONDENSATE"
    },
    {
      label: "Gas",
      key: "GAS"
    },
    {
      label: "Sales Gas",
      key: "SALES GAS"
    },
    {
      label: "Water",
      key: "WATER"
    },
    {
      type: "divider"
    },
    {
      label: "O+W",
      key: "O+W"
    },
    {
      label: "CGR",
      key: "CGR"
    },
    {
      label: "GOR",
      key: "GOR"
    },
    {
      label: "OGR",
      key: "OGR"
    },
    {
      label: "WGR",
      key: "WGR"
    },
    {
      label: "WOR",
      key: "WOR"
    },
    {
      label: "1+WOR",
      key: "1+WOR",
      showWhenKind: ["Forecasting"]
    },
    {
      label: "C2",
      key: "C2",
      showWhenKind: ["Forecasting"]
    },
    {
      label: "C3",
      key: "C3",
      showWhenKind: ["Forecasting"]
    },
    {
      label: "C4",
      key: "C4",
      showWhenKind: ["Forecasting"]
    },
    {
      label: "Shrinkage",
      key: "SHRINKAGE",
      showWhenKind: ["Forecasting"]
    }
  ].filter((item) => !item.showWhenKind || item.showWhenKind.includes(kind));
