import axios from "axios";

import { EntityKind } from "models/entityKind";

import { IGroupByListColumn, IGroupByListItem } from "../../../models";

const rootUrl = process.env.REACT_APP_COLUMN_SET_SERVICE;

const userColumnUrl = `${process.env.REACT_APP_DATA_ROOT}/api/v1/user-defined-column`;
const GeomBinPrefix = "GeomBin";
const UserDefinedColumnPropertyPrefix = "My_Focus_Fields";

export const fetchUserDefinedColumns = async (
  entityKind: EntityKind = EntityKind.Well
): Promise<IGroupByListItem> => {
  let userList: IGroupByListItem = null;

  const userDefinedColumnsResponse = await axios.get(
    `${rootUrl}/user-defined-columns?entityKind=${entityKind}`
  );

  if (userDefinedColumnsResponse.status === 200) {
    userList = userDefinedColumnsResponse.data;
  }

  if (userList && entityKind === EntityKind.Well) {
    const userGeomBinsResponse = await axios.get(`${userColumnUrl}/geombins?isOrg=false`);

    if (userGeomBinsResponse.status == 200 && userGeomBinsResponse.data) {
      const columns = userGeomBinsResponse.data.map((bin) => {
        return {
          title: bin.name,
          id: bin.id,
          canBin: false,
          dataType: "Text",
          canNormalize: false,
          group: GeomBinPrefix,

          subgroup: bin.group,
          property: `${UserDefinedColumnPropertyPrefix}_${GeomBinPrefix}_${btoa(
            bin.group
          )}_${bin.id}`,
          withinSubgroupOrder: bin.withinSubGroupOrder,
          fieldType: "geom"
        } as IGroupByListColumn;
      });

      userList.columns = userList.columns.concat(columns);
    }
  }

  return userList;
};
