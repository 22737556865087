import { useDispatch } from "react-redux";

import { MCDANIEL_FOLDER_ID, USER_ARPS } from "constants/settings.constants";
import {
  getSpecialFolders,
  setCheckedForecasts,
  setCheckedGlobalTypeWells,
  setCheckedKeys,
  setExpandedKeys,
  setUseMcDanielForecasts,
  setUseNoviForecasts,
  setUserForecastSettings
} from "store/features";

export function useClearForecasts() {
  const dispatch = useDispatch();

  function clearForecasts() {
    // TODO BF: cache

    const isUsingMcDanielForecasts = true;
    const isUsingNoviForecasts = false;

    dispatch(setUseMcDanielForecasts(isUsingMcDanielForecasts));
    dispatch(setUseNoviForecasts(isUsingNoviForecasts));

    dispatch(
      setUserForecastSettings({
        forecastSourceFolder: null,
        excludeMcDanielForecasts: !isUsingMcDanielForecasts,
        reserveCategory: null,
        excludeNoviForecasts: !isUsingNoviForecasts,
        specialForecastFolders: getSpecialFolders(
          isUsingMcDanielForecasts,
          isUsingNoviForecasts,
          false
        )
      })
    );

    dispatch(setCheckedKeys({ type: USER_ARPS, checkedKeys: [MCDANIEL_FOLDER_ID] }));
    dispatch(setExpandedKeys({ type: USER_ARPS, expandedKeys: [] }));
    dispatch(setCheckedGlobalTypeWells([]));
    dispatch(setCheckedForecasts({ type: USER_ARPS, checkedForecasts: [] }));
  }

  return { update: clearForecasts };
}
