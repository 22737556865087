import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Typography } from "antd";
import { setTypeWellSettings } from "store/features/userSettings/userSettingsSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { isRatio } from "utils/arps";
import { productStringToProductTypeEnum } from "utils/arps/getDefaultProductUnits";

import { defaultTypeWellProductSettings } from "api/useTypeWellSettings";
import { setDefaultTypeWell } from "api/userDefaults";

import { SegmentTemplate } from "components/arps/models/SegmentTemplate";
import { arpsWasm } from "components/arps/utils/UpdateSegment";
import { convertDecline } from "components/arps/utils/arpsUtils";
import { getDeclineTitleWithType } from "components/arps/utils/declineHelpers";
import { BaseButton, Tooltip } from "components/base";
import { ArpInput } from "components/shared";
import { useUserSettings } from "components/user/hooks";

const { Title } = Typography;

export default function TypeWellSettings() {
  const dispatch = useDispatch();
  const typeWellSettings = useSelector(
    (state: RootState) => state.userSetting.typeWellSettings
  );
  const { declineType } = useUserSettings();

  const [segmentTemplate, setSegmentTemplate] = useState<SegmentTemplate>();
  const segmentRef = useRef(segmentTemplate);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (!typeWellSettings || !declineType) {
      return;
    }

    handleReload();
  }, [typeWellSettings, declineType]);

  const handleReload = () => {
    const segments = {
      productCols: typeWellSettings.settings.map((s) => s.product),
      name: "Name",
      productData: typeWellSettings.settings.map((s) => s.values),
      rowHeaders: typeWellSettings.settings[0].headers,
      displayHeaders: defaultTypeWellProductSettings[0].displayHeaders
    } as SegmentTemplate;
    segmentRef.current = segments;
    setSegmentTemplate(segments);

    const errors = validateSegments(segments);
    setErrors(errors);
  };

  const handleArpsValueChanged = (product, header, val) => {
    const segmentIndex = segmentTemplate.productCols.findIndex(
      (name) => name === product
    );
    const segments = {
      ...segmentTemplate,
      productData: segmentTemplate.productData.map(
        (data: { [field: string]: number }, idx) => {
          if (idx === segmentIndex) {
            const copiedData = { ...data };
            if (header === "Di" || header === "Df") {
              copiedData[header] = convertToNominal(
                val,
                copiedData.b,
                declineType?.decline
              );
            } else {
              copiedData[header] = val;
            }

            return copiedData;
          }

          return data;
        }
      )
    } as SegmentTemplate;
    if (JSON.stringify(segments) !== JSON.stringify(segmentRef.current)) {
      setSegmentTemplate(segments);
      saveSettings(segments);
      segmentRef.current = segments;
    }
  };

  const validateSegments = (arpsSegment) => {
    const errors = [];
    arpsSegment.productCols.forEach((product, index) => {
      const segment = arpsSegment.productData[index];
      const lowercaseProduct = product.toLowerCase();
      if (segment.Qf && segment.Qi < segment.Qf && segment.Di > 0) {
        errors.push(`Invalid ${lowercaseProduct} segment: Qi cannot be less than Qf.`);
      }
      if (0 === segment.Q0 && 0 === segment.Qi && 0 === segment.Qf) {
        errors.push(`Invalid ${lowercaseProduct} segment: All flow rates are zero.`);
      }
      if (segment.Qf && segment.Qi > segment.Qf && segment.Di < 0) {
        errors.push(
          `Invalid ${lowercaseProduct} segment: negative decline requires Qf to be greater than Qi.`
        );
      }
      if (segment.Di === 0) {
        errors.push(`Invalid ${lowercaseProduct} segment: no decline (Di is 0).`);
      }
    });
    return errors;
  };

  const saveSettings = async (segments) => {
    /* Validate the segments, and let the user know if there are any errors.
       Note: If there are errors, the user default settings will still be saved.
             During the creation of a type well, the errors will appear again,
             and the user cannot save the type well until the errors are resolved.
     */
    const validationErrors = validateSegments(segments);
    if (validationErrors.length === 0) {
      setErrors([]);
    } else {
      setErrors(validationErrors);
    }

    const settings = {
      ...typeWellSettings,
      settings: typeWellSettings.settings.map((s) => {
        const segIndex = segments.productCols.findIndex((c) => c === s.product);
        return {
          product: s.product,
          headers: segments.rowHeaders,
          values: segments.productData[segIndex]
        };
      })
    };

    if (JSON.stringify(settings) === JSON.stringify(typeWellSettings)) {
      return;
    }

    const result = await setDefaultTypeWell(settings);
    if (result) {
      dispatch(setTypeWellSettings(settings));
      return;
    }
    toast.error("Error saving Type Well Settings.");
  };

  const resetToDefault = () => {
    const segments = {
      productCols: defaultTypeWellProductSettings.map((s) => s.product),
      name: "Name",
      productData: defaultTypeWellProductSettings.map((s) => s.values),
      rowHeaders: defaultTypeWellProductSettings[0].headers,
      displayHeaders: defaultTypeWellProductSettings[0].displayHeaders
    } as SegmentTemplate;

    setSegmentTemplate(segments);
    saveSettings(segments);
    segmentRef.current = segments;
  };

  function convertToNominal(di, b, type) {
    if (type === "Nominal") {
      return di / 100.0;
    }
    if (type === "Tangent") {
      return arpsWasm.tangentToNominal(di / 100.0);
    }

    return arpsWasm.secantToNominal(di / 100.0, b);
  }

  function getProductInputs(product: string, i: number, forecastConstant: boolean) {
    const segIndex = segmentTemplate.productCols.findIndex((c) => c === product);
    const producData = segmentTemplate.productData[segIndex] as { [key: string]: string };
    const convertedData = {
      ...producData,
      Di: convertDecline(producData.Di, producData.Btrans, declineType?.decline),
      Df: convertDecline(producData.Df, producData.Btrans, declineType?.decline)
    };

    return (
      <ProductColumnWrapper key={product + "" + i} width={120}>
        <ProductHeaderContainer>
          <SegmentHeader>{product}</SegmentHeader>
        </ProductHeaderContainer>
        <ColumnContainer>
          {(segmentTemplate?.rowHeaders ?? []).map((header) => (
            <RowWrapper key={header + i}>
              {ArpInput(
                header,
                productStringToProductTypeEnum(product),
                "Product",
                {
                  ...convertedData,
                  [header]: Math.round(convertedData[header] * 1000000) / 1000000 // Rounding to six decimal places to ensure values like .999999 are rounded up.
                },
                false,
                forecastConstant,
                false,
                null,
                null,
                null,
                null,
                (val) => {
                  handleArpsValueChanged(product, header, val);
                },
                null,
                null
              )}
            </RowWrapper>
          ))}
        </ColumnContainer>
      </ProductColumnWrapper>
    );
  }

  return (
    <RootContainer>
      <HeaderContainer>
        <Title level={5}>Arps Type Well Parameters</Title>
        <HeaderActionContainer>
          <Tooltip title={"Reset to default values"} placement="top">
            <BaseButton
              className="base-button"
              appearance="subtle"
              onClick={resetToDefault}>
              Reset
            </BaseButton>
          </Tooltip>
        </HeaderActionContainer>
      </HeaderContainer>
      {errors.length > 0 && (
        <div>
          {errors.map((error, index) => (
            <div key={index} style={{ color: "red" }}>
              {error}
            </div>
          ))}
        </div>
      )}
      {segmentTemplate && (
        <ContentContainer>
          <SectionBody>
            <Wrapper>
              <TableContainer>
                <ProductColumnContainer>
                  <ProductColumnWrapper>
                    <ColumnContainer>
                      {/*Header Column*/}
                      <RowHeading />
                      {(segmentTemplate?.rowHeaders ?? []).map((header, idx) => {
                        // Normal text will be before the dot
                        // Subscript text will be after the dot
                        const displayHeader =
                          segmentTemplate.displayHeaders[idx].split(".");

                        const head =
                          header === "Df" || header === "Di"
                            ? getDeclineTitleWithType(header, declineType?.decline)
                            : header;
                        const headerUnit = head.split(" ");
                        return (
                          <RowWrapper key={header}>
                            <RowHeading className="header" width={80}>
                              {displayHeader[0]}{" "}
                              {displayHeader.length > 1 ? (
                                <sub>
                                  {displayHeader[1]}{" "}
                                  {headerUnit.length > 1 ? headerUnit[1] : null}
                                </sub>
                              ) : null}
                            </RowHeading>
                          </RowWrapper>
                        );
                      })}
                    </ColumnContainer>
                  </ProductColumnWrapper>

                  {/*Product Columns*/}
                  {(segmentTemplate?.productCols ?? [])
                    .filter((p) => !isRatio(p))
                    .map((product, i) => getProductInputs(product, i, false))}
                </ProductColumnContainer>
              </TableContainer>
            </Wrapper>
          </SectionBody>
        </ContentContainer>
      )}
    </RootContainer>
  );
}

//Styled  Components
const RootContainer = styled.div`
  height: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  white-space: nowrap;

  .template-select {
    min-width: 250px;
  }

  .product-select {
    .ant-select {
      min-width: 180px;
    }
  }
`;

const HeaderActionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  border: 0;
  justify-content: flex-end;

  cursor: pointer;
  background: transparent;
  color: var(--color-primary);
  font-weight: var(--fontWeightMedium);

  &:hover {
    color: var(--color-text);
  }

  .ant-btn {
    min-width: 100px;
    justify-content: center;
    min-height: 40px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 750px;
  max-width: 900px;
  overflow: auto;
`;

const ContentContainer = styled.div`
  min-width: 750px;
  max-width: 900px;
  overflow: auto;
`;

const SectionBody = styled.div`
  padding: 33px 0;
`;

const TableContainer = styled.div`
  font-size: 1.3rem;

  .ant-btn {
    max-height: 26px;
  }

  .ant-select {
    height: 26px;
    font-size: 1.3rem;

    .ant-select-selection-search {
      display: flex;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-select-selector {
      height: 26px;
    }
  }

  .ant-input {
    padding: 0 3px;
    font-size: 1.3rem;
    height: 27px;
  }

  .ant-picker {
    padding: 0 5px;
  }

  .ant-picker-input > input {
    font-size: 1.3rem;
    height: 25px;
  }

  .sticky-col {
    position: sticky;
  }
`;

const ProductColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const ProductColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 110px;
  max-width: 110px;
  min-width: 110px;
  justify-content: flex-start;

  &:first-child {
    min-width: 80px;
    max-width: 80px;
    position: sticky;
    left: 0;
    z-index: 10;
    height: 28px;
    width: 100%;
    display: inline-block;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowHeading = styled.div`
  min-width: 80px;
  width: 80px;
  max-width: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 27px;
  padding-right: 5px;

  &:last-of-type {
    min-height: 26px;
  }
`;

const RowWrapper = styled.div`
  padding-top: 7px;
`;

const ProductHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 26px !important;
    width: 26px;
    height: 26px;

    &.ant-btn-circle {
      border-radius: 50%;
    }

    &:hover {
      background-color: var(--color-primary);
      color: var(--color-accent);
    }
  }

  .ant-select {
    width: 100px;
  }

  gap: 2px;
`;

const SegmentHeader = styled.div`
  width: 100%;
  text-align: left;
  font-weight: bold;
`;
