import { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Title from "antd/lib/typography/Title";
import { ICheckedForecast, SyncWells, setSyncWells } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import TypeWellInputTable from "components/arps/widget/TypeWellEditor/tabs/TypeWellInputTable";
import { useSelectedWellFromStore } from "components/data-analyzer/hooks";

import { DEFAULT_DECLINE_TYPE_SETTING } from "../user-settings/constants/userSetting.constants";
import { useUserSettings } from "../user/hooks";
import useUpdateForecastWell from "./hooks/updateForecastWell";
import useFetchForecastWell from "./hooks/useFetchForecastWell";
import { UpdateForecastWell } from "./models/forecastWell";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ForecastingProps {
  // wellHierarchy: WellHierarchy;
}
const Forecasting: FC<ForecastingProps> = () => {
  const selectedWell = useSelectedWellFromStore();
  const { data: forecastWell } = useFetchForecastWell(selectedWell?.Uwi || "");
  const { mutateAsync: updateForecast } = useUpdateForecastWell();
  const dispatch = useDispatch();

  const syncWells = useSelector((state: RootState) => state.app.syncWells);
  const syncWellsRef = useRef<SyncWells>(syncWells);

  const { declineType: declineTypeSetting } = useUserSettings();
  const declineType = declineTypeSetting?.decline ?? DEFAULT_DECLINE_TYPE_SETTING;
  const toExpectedBackendProductName = (product: string) => {
    switch (product.toUpperCase()) {
      case "SHRINKAGE":
        return "total shrinkage inlet";
      case "SALES GAS":
        return "sales gas";
      case "C2":
        return "ethYieldSales";
      case "C3":
        return "propYieldSales";
      case "C4":
        return "butYieldSales";
      default:
        return product;
    }
  };

  useEffect(() => {
    syncWellsRef.current = syncWells;
  }, [syncWells]);

  useEffect(() => {
    if (!forecastWell || !syncWellsRef.current) {
      return;
    }
    dispatch(
      setSyncWells({
        ...syncWellsRef.current,
        activeWells: [forecastWell.uwi]
      })
    );
  }, [forecastWell]);

  const handleForecastChange = async (item: ICheckedForecast) => {
    try {
      await updateForecast({
        uwi: forecastWell.uwi,
        update: {
          entityName: forecastWell.entityName,
          forecast: {
            segments: item.arps.map((seg) => ({
              ...seg,
              product: toExpectedBackendProductName(seg.product)
            })),
            constants: item.constants.map((fc) => ({
              ...fc,
              product: toExpectedBackendProductName(fc.product)
            }))
          }
        } as UpdateForecastWell
      });
    } catch (error) {
      toast.error("Failed to update forecast");
    }
  };

  return (
    <RootContainer>
      <StyledTitle level={5}>Forecasting {forecastWell?.entityName}</StyledTitle>
      {forecastWell && (
        <EditorWrapper>
          <TypeWellInputTable
            declineType={declineType}
            selectedTypeWellTitle={""}
            kind={"Forecasting"}
            arps={
              (forecastWell?.forecast as unknown as ICheckedForecast) ??
              ({ title: forecastWell?.entityName } as ICheckedForecast)
            }
            selectedTypeWellRescat={"P+PDP"}
            onChange={handleForecastChange}
          />
        </EditorWrapper>
      )}
      {!forecastWell && <div>Select a well from Well Hierarchy to start...</div>}
    </RootContainer>
  );
};

const StyledTitle = styled(Title)``;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
`;

const EditorWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export default Forecasting;
